import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Step,
  StepLabel,
  Grid,
  Stepper,
  Tooltip,
  Typography,
  Button as MUIButton,
  CircularProgress,
} from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "@material-ui/core/TextField";
import FiberNewOutlinedIcon from "@material-ui/icons/FiberNewOutlined";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { Autocomplete } from "@material-ui/lab";
import { default as React, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Table } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import { useHistory, useParams } from "react-router";
import gif from "../../assets/loopingcircles-3.gif";
import sampleExcel from "../../assets/SampleExcel/Sample_StudentDetails.xlsx";
import Shareicon from "../../assets/svg/share.svg";
import {
  cellCSS,
  createIconStyling,
  gifStyling,
  headerCSS,
  requestNiyuktiOptions,
  requestPraveshOptions,
  statusCodes,
} from "../../constants";
import "../../css/JobTrack.css";
import {
  apiCall,
  apiNiyuktiCall,
  niyuktiAxiosCall,
  niyuktiAxiosGetReq,
  gptAxiosGetReq,
} from "../../util/apiCall";
import { copyToClipboard } from "../../util/copyToClipboard";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { convertTitleCase, validateDateTime } from "../../util/printNumber";
import ImportData from "../DialogSection/ImportData/ImportData";
import UploadExcelFile from "../DialogSection/UploadExcel/UploadExcelFile";
import JobTrackFilter from "./Dialog/JobTrackFilter";
import UploadOfferLetter from "../DialogSection/UploadOfferLetter/UploadOfferLetter";
import UploadCreditNote from "../DialogSection/UploadCreditNote/UploadCreditNote";
import ShareApplicantTrack from "../TrackShare/ShareApplicantTrack";
import RateApplicant from "./Dialog/RateApplicant";
import JobStatus from "./JobStatus";
import QueryString from "query-string";
import GetAppIcon from "@material-ui/icons/GetApp";
import InfoIcon from "@material-ui/icons/Info";
import VideocamIcon from "@material-ui/icons/Videocam";
import AddIcon from "@material-ui/icons/Add";
import CustomButtonCircularProgress from "../loader";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DeleteIcon from "@material-ui/icons/Delete";
import { formatDateObject } from "../../util/utility";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import TuneIcon from "@material-ui/icons/Tune";
import AddCircleIcon from "@material-ui/icons/AddCircle";

const useStylesCustom = makeStyles((theme) => ({
  arrow: {
    color: "transparent",
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function CustomTooltip(props) {
  const classes = useStylesCustom();

  return <Tooltip arrow classes={classes} {...props} />;
}

const LINES_TO_SHOW = 2;

const useStyles = makeStyles((theme) => ({
  multiLineEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": LINES_TO_SHOW,
    "-webkit-box-orient": "vertical",
    minWidth: 100,
    maxWidth: 150,
    fontSize: 13,
  },

  buttonBase: {
    borderRadius: "30px",
    // margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  button: {
    borderRadius: "30px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
    backgroundColor: theme.palette.primary.secondary,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
}));

function JobTrack() {
  const { job_id } = useParams();
  const { comp_id } = useParams();

  const [stepList, setStepList] = useState({});
  const [actionDropDownList, setActionDropDownList] = useState({});
  const [stepData, setStepData] = useState({});

  const [callCountApi, setCallCountApi] = useState(false);

  const history = useHistory();

  let date = new Date();
  date.setDate(date.getDate() - 7);
  const [dateRange, setDateRange] = useState([null, null]);

  const filterInitialState = {
    selectedVendorList: [],
    selectedRecruiterList: [],
    selectedHrList: [],
    advanceFilterDateValue: dateRange,
    searchTerm: "",
  };
  const [filters, setFilters] = useState(filterInitialState);

  const getDateParam = () => {
    if (
      filters?.advanceFilterDateValue &&
      filters?.advanceFilterDateValue?.length === 2 &&
      filters?.advanceFilterDateValue[0] &&
      filters?.advanceFilterDateValue[1]
    ) {
      return {
        startDate: formatDateObject(filters.advanceFilterDateValue[0]),
        endDate: formatDateObject(filters.advanceFilterDateValue[1]),
      };
    }
    return {};
  };

  const [showFilter, setShowFilter] = useState(false);

  const [compName, setCompName] = useState("");
  const [compId, setCompId] = useState();
  const [jobTitle, setJobTitle] = useState("");
  const [paymentReceiveDate, setPaymentReceiveDate] = useState(null);
  const [jobRoleName, setJobRoleName] = useState("");
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [jobId, setJobId] = useState(job_id);
  const offerFlag = useRef(false);
  // const [companyId, setCompanyId] = useState(null);

  const queryParams = QueryString.parse(window.location.search);

  const [activeStep, setActiveStep] = useState(
    queryParams?.status_id ? `(${queryParams?.status_id})` : "(1)"
  );

  // const [activeStep, setActiveStep] = useState("(27)");
  const [open, setOpen] = useState(false);

  const [modalAssignmentsData, setModalAssignmentsData] = useState([]);
  const [modalFeedbacksData, setModalFeedbacksData] = useState([]);
  const [modalActionByData, setModalActionByData] = useState([]);
  const [modalAdminFeedbackData, setModalAdminFeedbackData] = useState([]);

  const [feedbackType, setFeedBackType] = useState(null);

  const [cityFilters, setCityFilters] = useState({});
  const [skillFilters, setSkillFilters] = useState({});
  // const [completed, setCompleted] = useState({});

  const [viewAssignmentModal, setViewAssignmentModal] = useState(false);
  const [viewFeedBackModal, setViewFeedBackModal] = useState(false);
  const [viewActionByModal, setViewActionByModal] = useState(false);
  const [viewAdminFeedbackModal, setViewAdminFeedbackModal] = useState(false);

  const [adminFeedback, setAdminFeedback] = useState("");
  const [adminFeedbackLoader, setAdminFeedbackLoader] = useState(false);

  const [subUsers, setSubUsers] = useState([]);
  const [subUserId, setSubUserId] = useState(null);
  const [comapnyJobList, setCompanyJobList] = useState([]);
  const classes = useStyles();
  const [excel, setExcel] = useState(null);
  const token = localStorage.getItem("token");
  const [openUpload, setOpenUpload] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [loadingUploadExcel, setLoadingUploadExcel] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(0);
  const [totalLoad, setTotalLoad] = useState(0);
  const [progress, setProgress] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [pendingAmount, setPendingAmount] = useState(null);
  const [jobInvoicingPeriod, setJobInvoicingPeriod] = useState();

  // res data if for upload via excel.
  // Flag is for progress loader control for various break up entries.

  const [resData, setresData] = useState({
    total_entries: null,
    user_created_count: null,
    email_already_exist: null,
    error_list_count: null,
    failed_entries: null,
    flag: null,
    error_list: [],
    already_exist_data: [],
    niyukti_error_list: [],
  });

  const ratingColumns = [
    {
      title: "Student_Rating",
      field: "student_score",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
      render: (row) => (
        <>
          <Box borderColor="transparent">
            <Typography
              style={{
                fontSize: 13,
              }}
              component="legend"
            >
              {row.overall_calculated_rating
                ? `${parseFloat(row.overall_calculated_rating)}(${
                    row.score_job_count
                  }
                 ${
                   row?.score_job_count && row?.score_job_count > 1
                     ? "Jobs"
                     : "Job"
                 })`
                : "New"}
            </Typography>
            {/* <Rating name="read-only-sdfs" defaultValue={row.student_score} readOnly max={10} /> */}
          </Box>
        </>
      ),
      customFilterAndSearch: (term, rowData) => {
        if (isNaN(term)) return false;

        if (rowData?.overall_calculated_rating)
          return (
            parseFloat(rowData.overall_calculated_rating) >= parseFloat(term)
          );
      },
    },

    {
      title: "Applicant_Name",
      field: "applicant_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
      render: (row) => (
        <div style={{ display: "flex" }}>
          {row.new_flag && (
            <>
              <FiberNewOutlinedIcon />{" "}
            </>
          )}

          <Typography
            style={{
              fontSize: 13,
              color: "blue",
              cursor: "pointer",
            }}
            component="legend"
            onClick={() => {
              window.open(
                `/admin/student-details/?email=${
                  row.applicant_email
                }&preAppliedJobId=${preApplied()}`
              );
            }}
          >
            {row?.applicant_name}
          </Typography>
        </div>
      ),
    },

    {
      title: "Payment_details",
      field: "payment_details",
      headerStyle: headerCSS,
      editable: "never",
      cellStyle: cellCSS,
      render: (row) => (
        <Button
          variant="outline-info"
          size="sm"
          style={{
            fontSize: 13,
          }}
          onClick={(e) => {
            handleClickOpenPaymentDetailsDialog(row);
          }}
          disabled={!row.invoicing_details?.payment_details?.payment_type}
        >
          Payment Details
        </Button>
      ),
    },

    {
      title: "Job_Rating",
      field: "job_Rating",
      headerStyle: headerCSS,
      editable: "never",
      cellStyle: cellCSS,
      render: (row) => (
        <Button
          variant="outline-info"
          size="sm"
          style={{
            fontSize: 13,
          }}
          onClick={(e) => {
            handleClickOpenRatingDialog(row);
          }}
          disabled={activeStep === "(21)" ? true : false}
        >
          {row?.student_score ? `${parseFloat(row.student_score)}` : "Rate Now"}
        </Button>
      ),
      customFilterAndSearch: (term, rowData) => {
        if (isNaN(term)) return false;

        if (rowData?.student_score)
          return parseFloat(rowData.student_score) >= parseFloat(term);
      },
    },
    {
      title: "Applicant_Email",
      field: "applicant_email",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
      render: (row) =>
        row.applicant_email ? (
          <div>
            {row.applicant_email}
            <FileCopyIcon
              style={createIconStyling}
              onClick={(e) => copyToClipboard(row.applicant_email)}
            />
          </div>
        ) : null,
    },
    {
      title: "Applicant_Phone",
      field: "applicant_phone",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
      render: (row) =>
        row.applicant_phone ? (
          <div>
            {row.applicant_phone}
            <FileCopyIcon
              style={createIconStyling}
              onClick={(e) => copyToClipboard(row.applicant_phone)}
            />
          </div>
        ) : null,
    },
    {
      title: "Ageing",
      field: "ageing",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
      searchable: false,
    },
    {
      title: "Gender",
      field: "applicant_gender",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      lookup: { Male: "Male", Female: "Female" },
      searchable: false,
      editable: "never",
      filterPlaceholder: "Select",
    },
    {
      title: "Applicant_Location",
      field: "applicant_city",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
      lookup: cityFilters,
      filterPlaceholder: "Select",
      searchable: false,
    },
    {
      title: "Current CTC",
      field: "currentCtc",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
    },
    {
      title: "Notice Period",
      field: "noticePeriod",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
    },
    {
      title: "Work_Experience",
      field: "work_exp",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      editable: "never",
      filterComponent: (props) => (
        <Slider
          style={{ zIndex: "10" }}
          value={props.columnDef.tableData.filterValue || [0, 120]}
          defaultValue={[0, 120]}
          onChange={(e, val) => handleChange(val, props)}
          min={0}
          max={120}
          step={10}
          marks={expLabel}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          valueLabelFormat={(e) => `${e} mo`}
        />
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.work_exp >= term[0] && rowData.work_exp <= term[1],
      render: (row) => `${row.work_exp} Months`,
    },
    {
      title: "HR_Name",
      field: "hr_info",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
      searchable: false,
    },
    {
      title: "Hiring_Date",
      field: "hiring_date",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
      searchable: false,
      render: (row) => {
        return row.hiring_date ? <div>{row.hiring_date}</div> : null;
      },
    },
    {
      title: "Joining_Date",
      field: "joining_date",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
      searchable: false,
    },
    {
      title: "Vendor Name",
      field: "vendor_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
      searchable: false,
    },
    {
      title: "Vendor User Name",
      field: "vendor_user_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
      searchable: false,
    },
    {
      title: "Assigned_SubUser",
      field: "assigned_sub_user_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
      editComponent: (props) => (
        <Form.Group as={Col} controlId="subUser">
          <Form.Control
            as="select"
            onChange={(e) => {
              setSubUserId(JSON.parse(e.target.value).user_id);
            }}
          >
            <option value={JSON.stringify({ user_id: null })}>
              --select--
            </option>
            {subUsers.map((subUser, key) => {
              return (
                <option key={key} value={JSON.stringify(subUser)}>
                  {subUser.first_name + " " + subUser.last_name}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
      ),
    },
    {
      title: "Degree",
      field: "degree",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
    },
    {
      title: "Specialization",
      field: "specialization",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
    },
    {
      title: "Applicant_Skills",
      field: "applicant_skills",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
      lookup: skillFilters,
      filterPlaceholder: "Select",
      searchable: false,
      customFilterAndSearch: (term, rowData) =>
        filterSkills(term, rowData.applicant_skills),
      render: (row) => (
        <Tooltip title={`${row.applicant_skills.toString()}`} placement="right">
          <div className={classes.multiLineEllipsis}>
            {row.applicant_skills.toString()}
          </div>
        </Tooltip>
      ),
    },

    {
      title: "Expected CTC",
      field: "expectedCtc",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
    },

    {
      title: "Others Remarks",
      field: "remarks",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
    },
    {
      title: "College_Name",
      field: "college_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
    },
    {
      title: "Temp_College_Name",
      field: "temp_college_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
    },
    {
      title: "Job_Round",
      field: "job_round_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
    },
    {
      title: "Assignments",
      field: "assignments",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      editable: "never",
      render: (row) => (
        <div>
          {row.assignment && row.assignment.length > 0 ? (
            <Button
              variant="outline-success"
              size="sm"
              onClick={(e) => loadAssignmentsModal(e, row.assignment)}
            >
              Assignments
            </Button>
          ) : (
            "No Assignment Submitted"
          )}
        </div>
      ),
    },
    {
      title: "Interview Time",
      field: "interview_scheduled",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      editable: "never",
      render: (row) => (
        <div>
          <div>{row.interview_scheduled}</div>
        </div>
      ),
    },
    {
      title: "Create_Time",
      field: "create_time",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      type: "datetime",
      editable: "never",
    },
    {
      title: "Update_Time",
      field: "update_time",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      type: "datetime",
      editable: "never",
    },
    {
      title: "Source & Campaign",
      field: "utmInfo",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },

    {
      title: "Applied_By",
      field: "applied_by",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
      searchable: false,
    },
    {
      title: "Action_By",
      field: "action_By",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
      render: (row) => (
        <Button
          variant="outline-info"
          size="sm"
          style={{
            fontSize: 13,
          }}
          onClick={(e) => loadActionByModal(e, row.action_taken)}
        >
          Action By
        </Button>
      ),
    },
    {
      title: "Admin_Feedback",
      title: "admin_Feedback",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <Button
          variant="outline-info"
          size="sm"
          style={{
            fontSize: 13,
          }}
          onClick={(e) => loadAdminFeedbackModal(e, row)}
        >
          Admin Feedback
        </Button>
      ),
    },
    {
      title: "View_Feedbacks",
      field: "view_Feedbacks",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
      render: (row) => (
        <Button
          variant="outline-info"
          size="sm"
          style={{
            fontSize: 13,
          }}
          onClick={(e) => loadFeedbackModal(e, row)}
        >
          Feedbacks
        </Button>
      ),
    },
    {
      title: "Candidate Resume",
      field: "resume",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      searchable: false,
      editable: "never",
      render: (row) => (
        <div>
          {row.resume ? (
            <Button
              variant="outline-success"
              size="sm"
              style={{
                fontSize: 13,
              }}
              onClick={() => window.open(row.resume)}
            >
              {" "}
              Resume
            </Button>
          ) : (
            <Button variant="outline-secondary" size="sm" disabled>
              {" "}
              Resume
            </Button>
          )}
        </div>
      ),
    },
    {
      title: "Share Assessment",
      field: "offer",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      searchable: false,
      editable: "never",
      render: (row) => (
        <div style={{ display: "flex" }}>
          <Button
            variant="outline-info"
            size="sm"
            style={{
              fontSize: 13,
              minWidth: 100,
              marginLeft: 5,
            }}
            disabled={shareMultiAssessementLoader || row.shareAssessmentLoader}
            onClick={() => {
              handleShareAssessment(row);
            }}
          >
            {row.shareAssessmentLoader ? (
              <CustomButtonCircularProgress />
            ) : (
              "Share"
            )}
          </Button>
        </div>
      ),
    },

    {
      title: "Offer Letter",
      field: "offer",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      searchable: false,
      editable: "never",
      render: (row) => (
        <div>
          {row?.offer_letter_file ? (
            <div style={{ display: "flex" }}>
              <Button
                variant="outline-info"
                size="sm"
                style={{
                  fontSize: 13,
                  minWidth: 100,
                  marginLeft: 5,
                }}
                disabled={!offerFlag.current}
                onClick={() => {
                  handleOpenOfferLetterUpdate(row, "Update");
                }}
              >
                Update
              </Button>

              <Button
                variant={
                  offerFlag.current ? "outline-success" : "outline-secondary"
                }
                size="sm"
                style={{
                  fontSize: 13,
                  minWidth: 100,
                  marginLeft: 5,
                }}
                disabled={!offerFlag.current}
                onClick={() => {
                  if (offerFlag.current) {
                    window.open(row?.offer_letter_file, "_blank");
                  }
                }}
              >
                {" "}
                View
              </Button>
            </div>
          ) : (
            <Button
              variant={
                offerFlag.current ? "outline-success" : "outline-secondary"
              }
              size="sm"
              style={{
                fontSize: 13,
              }}
              disabled={!offerFlag.current}
              onClick={() => {
                if (offerFlag.current) {
                  handleOpenOfferLetterUpdate(row, "Upload");
                }
              }}
            >
              {" "}
              Upload
            </Button>
          )}
        </div>
      ),
    },
    {
      title: "AI_Assessment_Score",
      field: "ai_assessment_score",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
      searchable: false,
    },
    {
      title: "AI_Calling_Score",
      field: "ai_calling_score",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
      searchable: false,
    },
    {
      title: "Resume_Score",
      field: "resume_score",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
      searchable: false,
    },
    {
      title: "AI Assessment Status & Summary",
      field: "ai_assessment",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      editable: "never",
      render: (row) => (
        <div style={{ width: "250px" }}>
          {row.candidate_assessment_id ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body2"
                  style={{ marginRight: 5, color: row.color }}
                >
                  {`${row.rating ? row.rating : "0"}% (${
                    row.recommendation_status ? row.recommendation_status : "NA"
                  })`}
                </Typography>
                <CustomTooltip
                  title={
                    <span style={{ fontWeight: 400 }}>
                      {row.interview_summary ? row.interview_summary : "NA"}
                    </span>
                  }
                  placement="bottom"
                >
                  <InfoIcon style={{ color: "#2563EB", cursor: "pointer" }} />
                </CustomTooltip>
              </div>
            </>
          ) : (
            <div>Assessment not given</div>
          )}
        </div>
      ),
    },
    {
      title: "AI Assessment Report",
      field: "ai_assessment_report",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      editable: "never",
      render: (row) => {
        return (
          <div style={{ width: "200px" }}>
            {row.candidate_assessment_id ? (
              <>
                {row.report ? (
                  <Button
                    variant="outline-success"
                    size="sm"
                    style={{
                      backgroundColor: "#2563EB",
                      color: "#fff",
                      width: "110px",
                    }}
                    onClick={() => window.open(row.report, "_blank")}
                  >
                    <GetAppIcon /> Download
                  </Button>
                ) : (
                  <Button
                    variant="outline-success"
                    size="sm"
                    style={{
                      backgroundColor: "#2563EB",
                      color: "#fff",
                      width: "110px",
                    }}
                    onClick={() => handleGenerate(row)}
                    disabled={generateLoading && row.user_id == currRowId}
                  >
                    {generateLoading && row.user_id == currRowId ? (
                      <CustomButtonCircularProgress />
                    ) : (
                      <>
                        <AddIcon /> Generate
                      </>
                    )}
                  </Button>
                )}
                {!row.screen_recording ? (
                  <CustomTooltip
                    title={
                      <span style={{ fontWeight: 400 }}>
                        Recording link not present
                      </span>
                    }
                    placement="top"
                  >
                    <VideocamIcon
                      fontSize="large"
                      style={{
                        marginLeft: "10px",
                        color: "#A0A0A0",
                        cursor: "not-allowed",
                      }}
                    />
                  </CustomTooltip>
                ) : (
                  <VideocamIcon
                    fontSize="large"
                    style={{
                      marginLeft: "10px",
                      color: "#2563EB",
                      cursor: "pointer",
                    }}
                    onClick={() => window.open(row.screen_recording, "_blank")}
                  />
                )}
              </>
            ) : (
              "Assessment not given"
            )}
          </div>
        );
      },
    },
    {
      title: "Credit_Note(pdf)",
      field: "credit_note",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      searchable: false,
      editable: "never",
      render: (row) => (
        <div>
          {row?.status_id != 25 ? (
            "NA"
          ) : row?.credit_note ? (
            <div style={{ display: "flex" }}>
              <Button
                variant={"outline-success"}
                size="sm"
                style={{
                  fontSize: 13,
                  marginLeft: 5,
                  width: "100px",
                }}
                onClick={() => {
                  window.open(row?.credit_note, "_blank");
                }}
              >
                View
              </Button>
            </div>
          ) : (
            <Button
              variant={"outline-success"}
              size="sm"
              style={{
                marginLeft: 5,

                fontSize: 13,
                width: "100px",
              }}
              onClick={() => {
                handleOpenCreditNoteUpload(row, "Upload");
              }}
            >
              {" "}
              Upload
            </Button>
          )}
        </div>
      ),
    },
    {
      title: "Lineup_Date",
      field: "lineup_date",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
      searchable: false,
      render: (row) => {
        return row.lineup_date ? <div>{row.lineup_date}</div> : "NA";
      },
    },
    {
      title: "Footfall_Date",
      field: "footfall_date",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      editable: "never",
      searchable: false,
      render: (row) => {
        return row.footfall_date ? <div>{row.footfall_date}</div> : "NA";
      },
    },
  ];
  // const [ratingColumns, setRatingColumns] = useState(initialRatingColumns);

  const getFilterString = () => {
    let filterStr = "";
    if (
      filters?.selectedVendorList.length ||
      filters?.selectedRecruiterList.length ||
      filters?.selectedHrList.length
    ) {
      let vendorFilterStr = "";
      filters?.selectedVendorList?.forEach((item, index) => {
        if (index !== filters?.selectedVendorList?.length - 1)
          vendorFilterStr = vendorFilterStr + item?.vendor_id + ",";
        else vendorFilterStr = vendorFilterStr + item?.vendor_id;
      });
      let recruiterFilterStr = "";
      filters?.selectedRecruiterList?.forEach((item, index) => {
        if (index !== filters?.selectedRecruiterList?.length - 1)
          recruiterFilterStr = recruiterFilterStr + item?.id + ",";
        else recruiterFilterStr = recruiterFilterStr + item?.id;
      });
      let hrFilterStr = "";
      filters?.selectedHrList?.forEach((item, index) => {
        if (index !== filters?.selectedHrList?.length - 1)
          hrFilterStr = hrFilterStr + item + ",";
        else hrFilterStr = hrFilterStr + item;
      });
      filterStr =
        (vendorFilterStr ? `&vendor_id=${vendorFilterStr}` : "") +
        (recruiterFilterStr ? `&recruiter_id=${recruiterFilterStr}` : "") +
        (hrFilterStr ? `&hr_names=${hrFilterStr}` : "");
    }
    const dateFilter = getDateParam();
    if (dateFilter?.startDate) {
      filterStr = filterStr + `&start_date=${dateFilter?.startDate}`;
    }
    if (dateFilter?.endDate) {
      filterStr = filterStr + `&end_date=${dateFilter?.endDate}`;
    }
    if (filters?.searchTerm) {
      filterStr = filterStr + `&search=${filters?.searchTerm}`;
    }

    return filterStr;
    // return stri ? `&vendor_id=${stri}` : stri;
  };

  // List of rounds & statuses

  const getApplicantCounts = () => {
    setLoading(true);
    apiNiyuktiCall(
      `/job/admin/track/applicants/count?company_id=${btoa(
        comp_id.toString()
      )}&job_id=${btoa(job_id.toString())}${getFilterString()}`,
      requestNiyuktiOptions
    ).then((json) => {
      if (json.success) {
        let obj = { ...json.data };
        let dict = {};
        Object.keys(obj).forEach((o) => {
          let str = "";
          str = `(${obj[o]["status_id"]}`;
          if (obj[o]["round_id"]) str += `,${obj[o]["round_id"]}`;
          str += ")";
          dict[str] = obj[o];
        });
        setStepList(dict);
        setActionDropDownList(dict);
      } else FailureAlert(json.error);
      setLoading(false);
      // setFilters(filterInitialState);
    });
  };
  useEffect(() => {
    if (job_id && comp_id) {
      getApplicantCounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job_id, comp_id, filters]);

  useEffect(() => {
    if (callCountApi) {
      setCallCountApi(false);
      getApplicantCounts();
    }
  }, [callCountApi]);

  useEffect(() => {
    if (job_id && comp_id) {
      niyuktiAxiosCall
        .get(`/job/company/job/list/?company_id=${btoa(comp_id.toString())}`)
        .then((res) => {
          if (res?.data?.success) {
            setCompanyJobList(res?.data?.data);
            res?.data?.data?.forEach((item) => {
              if (item?.job_id == parseInt(job_id)) {
                setJobInvoicingPeriod(item?.invoicing_period);
              }
            });
          } else {
            FailureAlert(`Error in job list: ${res.data.error}`);
          }
        })
        .catch((err) => {
          FailureAlert(err.message);
        });
    }
  }, []);

  useEffect(() => {
    setCompId(comp_id);
  }, []);

  const generateCurrDateISO = () => {
    const now = new Date();
    const isoDate = now.toISOString();

    return isoDate;
  };

  const submitAdminFeedback = () => {
    if (adminFeedback) {
      setAdminFeedbackLoader(true);
      const body =
        feedbackType === "admin"
          ? {
              job_applicant_id: jobApplicantId,
              admin_feedback: {
                status_name: stepList[activeStep]?.heading,
                feedback: adminFeedback,
              },
            }
          : {
              job_applicant_id: jobApplicantId,
              comment_dict: {
                user_id: -1,
                status_id: stepList[activeStep]?.status_id,
                status_name: stepList[activeStep]?.heading,
                comment: adminFeedback,
                create_time: generateCurrDateISO(),
                user_name: "Prashasan",
              },
            };
      niyuktiAxiosCall
        .post(
          feedbackType === "admin"
            ? `/job/admin/feedback/`
            : `api/manage_jobs/new/company/comments/`,
          body
        )
        .then((res) => {
          if (res.data.success) {
            // setModalAdminFeedbackData(res.data.data);
            SuccessAlert(res.data.data.message);
            setAdminFeedbackLoader(false);
            handleClose();
          } else {
            FailureAlert(`${res.data.error}`);
            setAdminFeedbackLoader(false);
          }
        })
        .catch((err) => {
          setAdminFeedbackLoader(false);
          FailureAlert(err.message);
        });
    } else {
      FailureAlert("Please enter feedback");
    }
  };

  const getApplicantAdminFeedback = (applicant_id) => {
    setAdminFeedbackLoader(true);
    niyuktiAxiosCall
      .get(`/job/admin/feedback/?job_applicant_id=${applicant_id}`)
      .then((res) => {
        if (res.data.success) {
          setModalAdminFeedbackData(
            res.data.data?.admin_feedback ? res.data.data?.admin_feedback : []
          );
        } else {
          FailureAlert(`${res.data.error}`);
        }
        setAdminFeedbackLoader(false);
      })
      .catch((err) => {
        FailureAlert(err.message);
        setAdminFeedbackLoader(false);
      });
  };

  const getApplicantFeedback = (applicant_id) => {
    setAdminFeedbackLoader(true);
    niyuktiAxiosCall
      .get(
        `api/manage_jobs/new/company/comments/?job_applicant_id=${applicant_id}`
      )
      .then((res) => {
        if (res.data.success) {
          setModalFeedbacksData(
            res.data.data?.comments ? res.data.data?.comments : []
          );
        } else {
          FailureAlert(`${res.data.error}`);
        }
        setAdminFeedbackLoader(false);
      })
      .catch((err) => {
        FailureAlert(err.message);
        setAdminFeedbackLoader(false);
      });
  };

  useEffect(() => {
    if (jobId && comp_id) history.push(`/admin/job-track/${jobId}/${comp_id}`);
  }, [jobId]);

  // Find applicants with status
  useEffect(() => {
    getApiCall();
  }, [count, stepList]);

  // find sub users of company
  useEffect(() => {
    if (compId) {
      apiCall(
        `/api/admin/company/user/?company_id=${compId}&is_verified=required&is_active=required`,
        requestPraveshOptions
      ).then((json) => {
        if (json.success) {
          setSubUsers(json.data);
          setCompName(json.data[0].company_name);
        } else {
          FailureAlert(json.error);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compId]);

  const updateList = () => {
    setCount(count + 1);
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setModalAssignmentsData([]);
    setModalFeedbacksData([]);
    setModalFeedbacksData([]);
    setModalActionByData([]);
    setModalAdminFeedbackData([]);
    setViewAssignmentModal(false);
    setViewFeedBackModal(false);
    setViewActionByModal(false);
    setViewAdminFeedbackModal(false);
    setAdminFeedback("");
  };

  const loadAssignmentsModal = (e, arr) => {
    setModalAssignmentsData(arr);
    setViewAssignmentModal(true);
    handleOpen();
  };

  const loadFeedbackModal = (e, row) => {
    setFeedBackType("company");
    setJobApplicantId(row.applicant_id);
    setJobApplicantName(row.applicant_name);
    setViewFeedBackModal(true);
    setModalFeedbacksData(row?.feedback);
    handleOpen();
    getApplicantFeedback(row?.applicant_id);
  };

  const loadActionByModal = (e, arr) => {
    setViewActionByModal(true);
    setModalActionByData(arr);
    handleOpen();
  };

  const loadAdminFeedbackModal = (e, row) => {
    setFeedBackType("admin");
    setJobApplicantId(row.applicant_id);
    setJobApplicantName(row.applicant_name);
    getApplicantAdminFeedback(row?.applicant_id);
    setViewAdminFeedbackModal(true);
    handleOpen();
  };

  const filterSkills = (term, skills) => {
    if (term.length === 0) return true;
    let difference = term.filter((x) => !skills.includes(x));
    if (difference.length === 0) return true;
    return false;
  };

  const handleChange = (newRange, props) => {
    props.onFilterChanged(props.columnDef.tableData.id, newRange);
  };

  const expLabel = [
    { value: 0, label: "0" },
    { value: 60, label: "60 mo" },
    { value: 120, label: "120 mo" },
  ];

  const getStepContent = (step) => {
    return (
      <JobStatus
        job_id={job_id}
        title={compName}
        columns={ratingColumns}
        editableObject={edit}
        updateList={updateList}
        stepList={stepList}
        actionDropDownList={actionDropDownList}
        currStatusId={stepList[step]["status_id"]}
        currRound={stepList[step]["heading"]}
        currRoundId={stepList[step]["round_id"]}
        statusName={stepList[step]["tooltip"]}
        data={stepData[step] === undefined ? [] : stepData[step]["data"]}
        setstepData={setStepData}
        step={step}
        shareMultiAssessementLoader={shareMultiAssessementLoader}
        setShareMultiAssessementLoader={setShareMultiAssessementLoader}
        pageNo={pageNo}
        setPageNo={setPageNo}
        totalCount={totalCount}
        setCallCountApi={setCallCountApi}
        setFilters={setFilters}
        filters={filters}
        jobInvoicingPeriod={jobInvoicingPeriod}
      />
    );
  };

  const [activeStepDetail, setActiveStepDetail] = useState({
    param: "&status_id=1,27,21",
  });

  const [pageNo, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState();

  const handleStep = (stepDict, step) => () => {
    // if (step === "(25)") {

    //   setRatingColumns([
    //     ...initialRatingColumns,
    //     {
    //       title: "Credit_Note(pdf)",
    //       field: "credit_note",
    //       headerStyle: headerCSS,
    //       cellStyle: cellCSS,
    //       filtering: false,
    //       searchable: false,
    //       editable: "never",
    //       render: (row) => (
    //         <div>
    //           {row?.credit_note ? (
    //             <div style={{ display: "flex" }}>
    //               <Button
    //                 variant={"outline-success"}
    //                 size="sm"
    //                 style={{
    //                   fontSize: 13,
    //                   marginLeft: 5,
    //                   width: "100px",
    //                 }}
    //                 onClick={() => {
    //                   window.open(row?.credit_note, "_blank");
    //                 }}
    //               >
    //                 View
    //               </Button>
    //             </div>
    //           ) : (
    //             <Button
    //               variant={"outline-success"}
    //               size="sm"
    //               style={{
    //                 marginLeft: 5,

    //                 fontSize: 13,
    //                 width: "100px",
    //               }}
    //               onClick={() => {
    //                 handleOpenCreditNoteUpload(row, "Upload");
    //               }}
    //             >
    //               {" "}
    //               Upload
    //             </Button>
    //           )}
    //         </div>
    //       ),
    //     },
    //   ]);
    // } else {
    //   const filteredColumns = initialRatingColumns.filter(
    //     (column) => column.title !== "Credit_Note(pdf)"
    //   );
    //   setRatingColumns(filteredColumns);
    // }
    if (step === "(18)")
      setActionDropDownList({
        "(25)": {
          status_id: "25",
          round_id: null,
          heading: "Joining Backout",
          tooltip: "Joining Backout",
          count: 0,
          param: "&status_id=25",
          group_name: "JOINING",
        },
        "(22)": {
          status_id: "22",
          round_id: null,
          heading: "Invoice Raised",
          tooltip: "Invoice Raised",
          count: 0,
          param: "&status_id=22",
          group_name: "INVOICE",
        },
        "(29)": {
          status_id: "29",
          round_id: null,
          heading: "Invoice Payment Received",
          tooltip: "Invoice Payment Received",
          count: 2,
          param: "&status_id=29",
          group_name: "INVOICE",
        },
      });
    else if (step === "(25)")
      setActionDropDownList({
        "(18)": {
          status_id: "18",
          round_id: null,
          heading: "Invoice In Process",
          tooltip: "Invoice In Process",
          count: 1,
          param: "&status_id=18",
          group_name: "INVOICE",
        },

        "(22)": {
          status_id: "22",
          round_id: null,
          heading: "Invoice Raised",
          tooltip: "Invoice Raised",
          count: 0,
          param: "&status_id=22",
          group_name: "INVOICE",
        },
        "(29)": {
          status_id: "29",
          round_id: null,
          heading: "Invoice Payment Received",
          tooltip: "Invoice Payment Received",
          count: 2,
          param: "&status_id=29",
          group_name: "INVOICE",
        },
      });
    else if (step === "(22)")
      setActionDropDownList({
        "(18)": {
          status_id: "18",
          round_id: null,
          heading: "Invoice In Process",
          tooltip: "Invoice In Process",
          count: 1,
          param: "&status_id=18",
          group_name: "INVOICE",
        },
        "(25)": {
          status_id: "25",
          round_id: null,
          heading: "Joining Backout",
          tooltip: "Joining Backout",
          count: 0,
          param: "&status_id=25",
          group_name: "JOINING",
        },

        "(29)": {
          status_id: "29",
          round_id: null,
          heading: "Invoice Payment Received",
          tooltip: "Invoice Payment Received",
          count: 2,
          param: "&status_id=29",
          group_name: "INVOICE",
        },
      });
    else if (step === "(29)")
      setActionDropDownList({
        "(18)": {
          status_id: "18",
          round_id: null,
          heading: "Invoice In Process",
          tooltip: "Invoice In Process",
          count: 1,
          param: "&status_id=18",
          group_name: "INVOICE",
        },
        "(25)": {
          status_id: "25",
          round_id: null,
          heading: "Joining Backout",
          tooltip: "Joining Backout",
          count: 0,
          param: "&status_id=25",
          group_name: "JOINING",
        },
        "(22)": {
          status_id: "22",
          round_id: null,
          heading: "Invoice Raised",
          tooltip: "Invoice Raised",
          count: 0,
          param: "&status_id=22",
          group_name: "INVOICE",
        },
      });
    else {
      setActionDropDownList(stepList);
    }
    setActiveStep(step);
    setActiveStepDetail(stepDict);
    setTotalCount(0);
    setPageNo(1);
  };

  const [getFeedbackData, setGetFeedBackData] = useState(false);

  const getApiCall = (isPage = false) => {
    if (!job_id) {
      FailureAlert("Incorrect Job ID");
      return;
    }
    if (Object.keys(stepList).length === 0) {
      return;
    }
    setStepData({});
    let currPage = isPage ? pageNo : 1;
    if (!isPage) {
      setPageNo(1);
    }
    apiNiyuktiCall(
      `/api/manage_jobs/admin/track/applicants/?job_id=${jobId}&page_no=${currPage}${
        activeStepDetail?.param
      }&page_size=10${getFilterString()}`,
      requestNiyuktiOptions
    ).then((json1) => {
      if (json1.success) {
        let dataObj = {};
        let tempCity = {};
        let tempSkill = {};
        let tempVendorDetails = {};
        let ctr = {};
        let json = { data: [] };
        json.data = json1?.data?.job_applicants_data_list;
        setTotalCount(json1?.data?.total_no_pages);
        for (var i = 0; i < json1?.data?.job_applicants_data_list.length; i++) {
          let obj = {
            shareAssessmentLoader: false,
            applicant_id: json.data[i].applicant_id,
            user_id: json.data[i].applicant
              ? json.data[i].applicant.user_id
              : "",
            applied_by: json.data[i]?.applied_by
              ? json.data[i].applied_by?.name
              : "",
            applicant_name: json.data[i].applicant
              ? json.data[i].applicant.first_name +
                " " +
                json.data[i].applicant.last_name
              : "",
            first_name: json.data[i].applicant
              ? json.data[i].applicant.first_name
              : "",
            last_name: json.data[i].applicant
              ? json.data[i].applicant.last_name
              : "",
            applicant_email: json.data[i].applicant
              ? json.data[i].applicant.email
              : "",
            applicant_phone: json.data[i].applicant
              ? json.data[i].applicant.phone
              : "",
            ageing: json.data[i].ageing,
            hiring_date: json.data[i].hiring_date,
            joining_date: json.data[i].joining_date,
            applicant_gender: json.data[i].applicant
              ? json.data[i].applicant.gender
              : "",
            resume:
              json.data[i].applicant && json.data[i].applicant.resume_link,
            applicant_city: json.data[i].applicant
              ? json.data[i].applicant.current_city_name
              : "",
            college_name: json.data[i].college
              ? json.data[i].college.college_name
              : "",
            temp_college_name: json.data[i].applicant.temp_college_name
              ? json.data[i].applicant.temp_college_name
              : "",
            company_id: json.data[i].company
              ? json.data[i].company.company_id
              : "",
            company_name: json.data[i].company
              ? json.data[i].company.company_name
              : "",
            interview_scheduled:
              json.data[i].interview_scheduled_start_date ?? "NA",
            interview_summary: json.data[i].interview_summary,
            offered_ctc: json.data[i].offered_ctc,
            invoicing_details: json.data[i].invoicing_details,
            rating: json.data[i].rating,
            hr_info: json.data[i].hr_info ? json.data[i].hr_info : "NA",
            recommendation_status: json.data[i].recommendation_status,
            screen_recording: json.data[i].screen_recording,
            report: json.data[i].report,
            color: json.data[i].color,
            candidate_assessment_id: json.data[i].candidate_assessment_id,
            application_status: json.data[i].status_name,
            job_round_id: json.data[i].round_id,
            job_round_name: json.data[i].round_name,
            status_id: json.data[i].status_id,
            job_title: json.data[i].job_title,
            job_role_name: json.data[i].job_role_name,
            job_status: json.data[i].job_status,
            create_time: validateDateTime(json.data[i].create_time),
            update_time: validateDateTime(json.data[i].update_time),
            feedback:
              json.data[i].feedback &&
              json.data[i].feedback.filter((m) => m && "status_name" in m),
            action_taken: json.data[i].action_taken || [],
            assignment: json.data[i].assignment,
            applicant_skillObj: json.data[i]?.applicant?.skills
              ? json.data[i].applicant.skills
              : [],
            work_exp: json.data[i]?.applicant?.work_exp
              ? json.data[i].applicant.work_exp
              : 0,
            currentCtc: json.data[i]?.applicant?.cur_ctc,
            expectedCtc: json.data[i]?.applicant?.expected_ctc,
            noticePeriod: json.data[i]?.applicant?.notice_period,
            remarks: json.data[i]?.applicant?.remark,
            degree: json.data[i]?.applicant?.degree
              ? json.data[i].applicant.degree
              : "",
            specialization: json.data[i]?.applicant?.specialization
              ? json.data[i].applicant.specialization
              : "",
            assigned_sub_user: json.data[i]?.assigned_company_user,
            student_score: json.data[i]?.student_score,
            overall_calculated_rating: json.data[i].overall_calculated_rating,
            overall_applicant_score: json.data[i].overall_applicant_score,
            score_job_count: json.data[i].score_job_count,
            vendor_name: json?.data[i]?.vendor_name,
            vendor_user_name: json?.data[i]?.vendor_user_name,
            new_flag: json.data[i].new_flag,
            taxable_amount: json.data[i].taxable_amount,
            assigned_sub_user_name:
              json.data[i].assigned_company_user &&
              json.data[i].assigned_company_user.length > 0
                ? `${json.data[i].assigned_company_user[0].user_details.first_name} ${json.data[i].assigned_company_user[0].user_details.last_name}`
                : "",
            utmInfo: json?.data[i]?.utm_info,
            ai_assessment_score: json?.data[i]?.student_ai_assessment_score,
            ai_calling_score: json?.data[i]?.student_ai_calling_score,
            resume_score: json?.data[i]?.student_jd_vs_resume_score,
            offer_letter_id: json.data[i].offer_letter_id ?? null,
            offer_letter_file: json.data[i].offer_letter_file ?? null,
            offer_letter_status: json.data[i].offer_letter_status ?? null,
            credit_note: json.data[i]?.credit_note,
            lineup_date: json.data[i]?.lineup_date,
            footfall_date: json.data[i]?.footfall_date,
          };
          if (obj.applicant_city)
            tempCity[obj.applicant_city] = obj.applicant_city;
          obj.applicant_skillObj.forEach((s) => {
            if (s?.skill_name)
              tempSkill[convertTitleCase(s.skill_name)] = convertTitleCase(
                s.skill_name
              );
          });
          let skill = [];
          obj.applicant_skillObj.forEach((s) => {
            if (s?.skill_name) skill.push(convertTitleCase(s.skill_name));
          });
          obj["applicant_skills"] = skill;
          setCompName(obj.company_name);
          //  setCompId(obj.company_id);
          setJobRoleName(obj.job_role_name);
          setJobTitle(obj.job_title);
          let str = "";
          str = [1, 21, 27].includes(obj["status_id"])
            ? `(1`
            : `(${obj["status_id"]}`;
          // str = `(${obj["status_id"]}`;
          if (obj["job_round_id"] && obj["status_id"] === 7)
            str += `,${obj["job_round_id"]}`;
          str += ")";
          if (dataObj[str] === undefined) {
            ctr[str] = 0;
            dataObj[str] = {};
            obj["index"] = ctr[str];
            dataObj[str]["data"] = [obj];
            dataObj[str]["count"] = 1;
            ctr[str]++;
          } else {
            obj["index"] = ctr[str];
            dataObj[str]["data"].push(obj);
            dataObj[str]["count"]++;
            ctr[str]++;
          }
        }
        setStepData(dataObj);
        setCityFilters(tempCity);
        setSkillFilters(tempSkill);

        // setLoading(false);
      } else {
        FailureAlert(json1.error);
      }
    });
  };

  useEffect(() => {
    if (activeStepDetail || getFeedbackData) {
      getApiCall();
      setGetFeedBackData(false);
    }
  }, [activeStepDetail, getFeedbackData]);

  useEffect(() => {
    getApiCall(true);
  }, [pageNo]);

  const edit = {
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          let temp = [];
          if (!subUserId) {
            FailureAlert("Select subuser");
          } else {
            temp.push({
              applicant_id: oldData.applicant_id,
              status: oldData.status_id,
              user_id: subUserId,
              is_active: 1,
            });
            const requestOptionsPost = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${localStorage.getItem("token")}`,
              },
              body: JSON.stringify({
                student_data: temp,
              }),
            };
            apiNiyuktiCall(
              `/job/company/assign_user/`,
              requestOptionsPost
            ).then((res) => {
              if (res.success) {
                SuccessAlert(res.data.message);
                setSubUserId(null);
                setCount(count + 1);
              } else FailureAlert(res.error);
            });
          }
          resolve();
        }, 5);
      }),
  };

  const handleClickOpenUpload = () => {
    setOpenUpload(true);
  };

  const handleCloseUpload = () => {
    setOpenUpload(false);
    setFileNames([]);
  };

  const handleOpenImport = () => {
    setProgress(0);

    // setOpenUpload(false);

    setOpenImport(true);
    if (excel) {
      const ext = excel.name.substring(excel.name.lastIndexOf(".") + 1);
      if (ext !== "xlsx" && ext !== "xls") {
        FailureAlert("Upload Excel File Only...");
      } else {
        const formData = new FormData();
        formData.append("file", excel);
        formData.append("job_id", job_id);

        const requestOptionsPost = {
          method: "POST",
          headers: { Authorization: `Token ${token}` },
          body: formData,
        };
        // setLoading(true);
        setLoadingUploadExcel(true);

        apiCall(
          "/api/admin/operations/track/bulk/apply/",
          requestOptionsPost
        ).then((res) => {
          if (res?.success) {
            setresData(res?.data);
            setresData((resData) => ({ ...resData, flag: res?.success }));
            setresData((resData) => ({
              ...resData,
              error_list: res?.data?.error_list
                ? [...res?.data?.error_list]
                : [],
            }));
            setresData((resData) => ({
              ...resData,
              niyukti_error_list: res?.data?.niyukti_response?.error_list
                ? [...res?.data?.niyukti_response?.error_list]
                : [],
            }));
            setProgress(100);
            SuccessAlert("Upload Successful...");
            setExcel(null);
            setOpenUpload(false);
            setLoadingUploadExcel(false);
            getApiCall();
          } else {
            FailureAlert(res?.error);
            setExcel(null);
            setOpenUpload(false);
            setLoadingUploadExcel(false);
          }
          // setLoading(false)
        });
      }
    } else {
      if (excel === null) {
        FailureAlert("Upload file...");
        return;
      }
    }
  };

  const handleCloseImport = () => {
    setOpenImport(true);
    setFileNames([]);
  };

  const handleCancelImport = () => {
    setOpenImport(false);
    setresData((resData) => ({ ...resData, flag: false }));
    getApiCall();
  };

  const [openRatingDialog, setOpenRatingDialog] = React.useState(false);
  const [jobApplicantId, setJobApplicantId] = useState(null);
  const [jobApplicantName, setJobApplicantName] = useState(null);
  const [rowDataForRating, setrowDataForRating] = useState(null);
  const [shareOpen, setShareOpen] = useState(false);

  const handleClickOpenRatingDialog = (row) => {
    setrowDataForRating(row);
    setJobApplicantId(row?.applicant_id);
    setJobApplicantName(row?.applicant_name);
    setOpenRatingDialog(true);
  };
  const [statusMoveId, setStatusMoveId] = React.useState("(20)");
  const handleCloseRatingDialog = () => {
    setStatusMoveId("(20)");
    setOpenRatingDialog(false);
  };

  // only for preApplied step we do this for auto filling of job details in apply and rate
  const preApplied = () => {
    if (activeStep === "(21)") {
      return job_id;
    } else {
      return false;
    }
  };

  const [generateLoading, setGenrateLoading] = useState(false);
  const [currRowId, setCurrRowId] = useState(null);

  const handleGenerate = async (rowData) => {
    try {
      setCurrRowId(rowData.user_id);
      setGenrateLoading(true);
      const res = await gptAxiosGetReq(
        `/job/admin/generate_ai_assessment_report/?job_id=${job_id}&user_id=${rowData.user_id}`
      );
      if (res?.data?.success) {
        const newStepData = stepData[activeStep]["data"].map((row) => {
          if (row.user_id === rowData.user_id) {
            return {
              ...row,
              report: res?.data?.data?.public_url,
              interview_summary: res?.data?.data?.interview_summary,
              rating: res?.data?.data?.rating,
              recommendation_status: res?.data?.data?.recommendation_status,
              color: res?.data?.data?.color,
            };
          }
          return row;
        });
        setStepData((prevStepData) => ({
          ...prevStepData,
          [activeStep]: {
            ...prevStepData[activeStep],
            data: newStepData,
          },
        }));
      } else {
        FailureAlert("Error in generating report");
      }
      setGenrateLoading(false);
    } catch (err) {
      FailureAlert("Error: ", err);
      setGenrateLoading(false);
    }
  };

  const [shareMultiAssessementLoader, setShareMultiAssessementLoader] =
    useState(false);

  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const handleClickOpenPaymentDetailsDialog = (row) => {
    setSelectedRow(row);
    setOpenPaymentDialog(true);
  };

  const handleShareAssessment = (row) => {
    if (row?.user_id) {
      setStepData((prev) => ({
        ...prev,
        "(1)": {
          ...prev["(1)"],
          data: prev["(1)"].data.map((item, index) => {
            if (index === row.index) {
              return {
                ...item,
                shareAssessmentLoader: true,
              };
            }
            return item;
          }),
        },
      }));
      // const updatedStepData = {
      //   ...stepData,
      //   "(1)": {
      //     ...stepData["(1)"],
      //     data: stepData["(1)"].data.map((item, index) => {
      //       if (index === row.index) {
      //         return {
      //           ...item,
      //           shareAssessmentLoader: true,
      //         };
      //       }
      //       return item;
      //     }),
      //   },
      // };

      // setStepData(updatedStepData);

      niyuktiAxiosGetReq(
        `/job/admin/send_assessment_to_candidate/?job_id=${jobId}&candidate_id=${row?.user_id}`
      )
        .then((res) => {
          if (res.data.success) {
            SuccessAlert(res.data?.data?.message);
          } else {
            FailureAlert(res.data?.error);
          }
          setStepData((prev) => ({
            ...prev,
            "(1)": {
              ...prev["(1)"],
              data: prev["(1)"].data.map((item, index) => {
                if (index === row.index) {
                  return {
                    ...item,
                    shareAssessmentLoader: false,
                  };
                }
                return item;
              }),
            },
          }));
        })
        .catch((err) => {
          FailureAlert(err.message);
          setStepData((prev) => ({
            ...prev,
            "(1)": {
              ...prev["(1)"],
              data: prev["(1)"].data.map((item, index) => {
                if (index === row.index) {
                  return {
                    ...item,
                    shareAssessmentLoader: false,
                  };
                }
                return item;
              }),
            },
          }));
        });
    }
  };

  const [openOfferLetterUpdate, setOpenOfferLettersUpdate] = useState(false);
  const [offerLetterdataForUpdate, setOfferLetterdataForUpdate] =
    useState(null);
  const handleCloseOfferLetterUpdate = () => {
    setOpenOfferLettersUpdate(false);
  };
  const handleOpenOfferLetterUpdate = (data, type) => {
    const obj = {
      type: type,
      offerLetterId: data?.offer_letter_id,
      userId: data?.user_id,
      jobId: job_id,
      companyId: data?.company_id,
      source: "Prashasan Job Track",
    };
    setOfferLetterdataForUpdate(obj);
    setOpenOfferLettersUpdate(true);
  };

  const [openCreditNoteUpload, setOpenCreditNoteUpload] = useState(false);
  const [creditNoteData, setCreditNoteData] = useState(null);
  const handleCloseCreditNoteUpload = () => {
    setOpenCreditNoteUpload(false);
  };
  const handleOpenCreditNoteUpload = (data, type) => {
    const obj = {
      applicantId: data?.applicant_id,
    };
    setCreditNoteData(obj);
    setOpenCreditNoteUpload(true);
  };

  const shareColumns = [
    {
      field: "name",
      title: "Candidate Name",
    },
    {
      field: "email",
      title: "Email",
    },
    {
      field: "phone",
      title: "Phone",
    },
    {
      field: "current_salary",
      title: "Current Salary",
    },
    {
      field: "expected_salary",
      title: "Expected Salary",
    },
    {
      field: "location",
      title: "Current Location",
    },
    {
      field: "experience",
      title: "Experience",
    },
    {
      field: "current_title",
      title: "Current Title",
    },
    {
      field: "notice_period",
      title: "Notice Period",
    },
    {
      field: "skills",
      title: "Key Skills",
    },
    {
      field: "score",
      title: "Relevance Score",
    },
    {
      field: "key_skill_score",
      title: "Key Skill Score",
    },
    {
      field: "communication_score",
      title: "Comm. Skill Score",
    },

    {
      field: "education",
      title: "Highest Education",
    },
    {
      field: "preferred_location",
      title: "Preferred Location",
    },
    {
      field: "applied_on",
      title: "Applied On",
    },
    {
      field: "resume",
      title: "Resume",
    },
    {
      field: "assignment_list",
      title: "Assignment",
    },
    {
      field: "Comments",
      title: "Comments",
    },
    {
      field: "hr_info",
      title: "HR Name",
    },
  ];

  useEffect(() => {
    if (["(17)", "(13)", "(18)"].includes(activeStep)) {
      offerFlag.current = true;
    } else {
      offerFlag.current = false;
    }
  }, [activeStep]);

  const [jobSkills, setJobSkills] = useState([]);
  // http://127.0.0.1:8000/api/manage_jobs/admin/job_skills/?job_id=677
  useEffect(() => {
    niyuktiAxiosGetReq(`/api/manage_jobs/admin/job_skills/?job_id=${jobId}`)
      .then((res) => {
        if (res.data.success) {
          setJobSkills(res.data.data.data);
        } else {
          FailureAlert(res.data.error);
        }
      })
      .catch((err) => {
        FailureAlert(err.message);
      });
  }, [jobId]);

  const pendingPaymentSend = () => {
    let requestBody = {
      student_data: [
        {
          id: selectedRow.applicant_id,
          round: null,
          status: 29,
          // feedback: null,
          user_id: selectedRow.user_id,
          job_id: job_id,
          company_id: selectedRow.company_id,
        },
      ],
    };

    if (!paymentReceiveDate) {
      FailureAlert("Please enter the complete Invoice Details");
      return;
    }
    requestBody.invoicing_details = {
      ...(selectedRow?.invoicing_details || {}),
      total_bill_value: selectedRow?.invoicing_details?.total_bill_value,
      payment_details: {
        ...(selectedRow?.invoicing_details?.payment_details || {}),
        payment_type: "Partial Amount",
        payments: [
          ...(selectedRow?.invoicing_details?.payment_details?.payments || []),
        ],
      },
    };

    if (
      selectedRow?.invoicing_details?.payment_details?.payments instanceof Array
    ) {
      requestBody.invoicing_details.payment_details.payments.push({
        // tds: tds,
        // net_amount_recieved: netAmountRecieved,
        received_amount: pendingAmount,
        date_of_receive: paymentReceiveDate.toISOString().slice(0, 10),
        // pending_amount: pendingAmount,
        // notes: notes,
      });
    } else {
      requestBody.invoicing_details.payment_details.payments = [
        {
          // tds: tds,
          // net_amount_recieved: netAmountRecieved,
          received_amount: pendingAmount,
          date_of_receive: paymentReceiveDate.toISOString().slice(0, 10),
          // pending_amount: pendingAmount,
          // notes: notes,
        },
      ];
    }

    const requestUpdate = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(requestBody),
    };

    apiNiyuktiCall("/job/admin/status_update/", requestUpdate).then((json) => {
      if (json.success) {
        SuccessAlert(json.data.message);
        setOpenPaymentDialog(false);
        setPaymentReceiveDate(null);
        setPendingAmount(null);
      } else {
        FailureAlert(json.error);
        setOpenPaymentDialog(false);
      }
    });
  };

  const [loadingDownload, setLoadingDownload] = useState(false);

  const handleDownloadExcel = () => {
    setLoadingDownload(true);
    apiNiyuktiCall(
      `/api/manage_jobs/admin/track/applicants/?job_id=${jobId}${
        activeStepDetail?.param
      }&page_no=1&is_export=1${getFilterString()}`,
      requestNiyuktiOptions
    ).then((json) => {
      if (json.success) {
        SuccessAlert(json?.data?.message);
      } else FailureAlert(json.error);
      setLoadingDownload(false);
    });
  };

  function formatDate(timestamp) {
    const date = new Date(timestamp);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const hours = String(date.getHours() % 12 || 12).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = date.getHours() >= 12 ? "PM" : "AM";

    return `${day}-${month}-${year} at ${hours}:${minutes} ${ampm}`;
  }

  return !loading ? (
    <div className="job__track">
      <RateApplicant
        jobSkills={jobSkills}
        open={openRatingDialog}
        handleClickOpen={handleClickOpenRatingDialog}
        handleClose={handleCloseRatingDialog}
        jobApplicantId={jobApplicantId}
        getApiCall={getApiCall}
        name={jobApplicantName}
        jobTitle={jobTitle}
        jobId={job_id}
        stepList={stepList}
        rowData={rowDataForRating}
        statusMoveId={statusMoveId}
        setStatusMoveId={setStatusMoveId}
        stepData={stepData}
        setStepData={setStepData}
        setCallCountApi={setCallCountApi}
      />
      <Stepper nonLinear activeStep={activeStep}>
        {Object.keys(stepList).map((label, index) => (
          <Step
            id={activeStep === label ? "stepper_active" : "stepper"}
            key={label}
          >
            <StepLabel
              style={{ cursor: "pointer" }}
              onClick={handleStep(stepList[label], label)}
            >
              {`${stepList[label]["tooltip"]} (${
                stepList[label] ? stepList[label]["count"] : 0
              })`}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <section style={{ display: "flex", alignItems: "flex-start" }}>
        <Form.Row style={{ width: "70%", marginLeft: "5%" }}>
          <Form.Group as={Col} controlId="jobTitle">
            <Form.Control
              name="jobTitle"
              type="text"
              readOnly
              placeholder={` ${compName}`}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="jobRoleName">
            <Autocomplete
              id="select-job"
              options={comapnyJobList}
              getOptionLabel={(item) =>
                `${item.job_title} - ${item.job_id} (${item.hiring_type})`
              }
              style={{ width: "94%", marginRight: "3%" }}
              onChange={(e, item) => {
                item ? setJobId(item.job_id) : setJobId(null);
                setJobInvoicingPeriod(item?.invoicing_period);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={` ${jobTitle || `NONE`}- ${jobId || job_id}`}
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </Form.Group>
          {/* <Share
            jobProfileName={jobTitle}
            jobProfileObject={{
              jobTitle: jobTitle,
              jobId: job_id,
              compId: comp_id,
            }}
            fromWithoutAction={true}
          /> */}
          {/* <ShareApplicantTrack
           jobProfileName={jobTitle}
           jobProfileObject={{
              jobTitle: jobTitle,
              jobId: job_id,
              compId: comp_id,
            }}
            fromWithoutAction={true}  
            ratingColumns={shareColumns}  
            stepList={stepList}  
          /> */}
          <MUIButton
            size="small"
            variant="contained"
            color="inherit"
            onClick={() => {
              setShareOpen(true);
            }}
            style={{
              height: "40px",
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={Shareicon} height="20px" width="20px" /> &nbsp;{" "}
              <span
                style={{
                  fontSize: 14,
                }}
              >
                Share
              </span>
            </div>
          </MUIButton>
        </Form.Row>

        <Box
          style={{
            height: "40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginRight: 20,
            marginLeft: 20,
          }}
        >
          <Tooltip title="Filter" placement="bottom">
            <IconButton
              // style={{
              //   width: "8rem",
              //   height: "2.5rem",
              //   marginRight: "5rem",
              // }}
              onClick={() => {
                setShowFilter(true);
              }}
              size="small"
            >
              <TuneIcon />
              {/* Filters */}
            </IconButton>
          </Tooltip>
        </Box>
        <Box style={{ marginRight: 10 }}>
          <MUIButton
            variant="contained"
            color="primary"
            style={{
              height: "40px",
              boxShadow: "none",

              // backgroundColor: "transparent",
            }}
            // onClick={() => window.open(`/admin/student-details?jobId=${job_id}`)}
            onClick={() => {
              window.location.href = `/admin/student-details?jobId=${job_id}&companyId=${comp_id}`;
            }}
            startIcon={<AddCircleIcon />}
          >
            Student
          </MUIButton>
        </Box>
        <Box style={{ marginRight: 20, marginLeft: 20 }}>
          <MUIButton
            // variant="contained"
            style={{ height: "40px", boxShadow: "none" }}
            color="primary"
            onClick={handleClickOpenUpload}
            className={`classes.buttonBase classes.button`}
            startIcon={<CloudUploadIcon />}
          >
            Excel
          </MUIButton>
        </Box>
        <Box
          style={{
            height: "40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Tooltip title="Download Excel" placement="bottom">
            <IconButton onClick={handleDownloadExcel} size="small">
              {loadingDownload ? (
                <CircularProgress size={20} />
              ) : (
                <CloudDownloadIcon />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </section>

      <section>
        <UploadExcelFile
          handleClickOpen={handleClickOpenUpload}
          handleClose={handleCloseUpload}
          open={openUpload}
          fileNames={fileNames}
          setFileNames={setFileNames}
          handleOpenImport={handleOpenImport}
          excel={excel}
          setExcel={setExcel}
          // dataRow={dataRow}
          // headerSampleFile={headerSampleFile}
          downloadText={"Sample_Company.csv"}
          sampleFile={sampleExcel}
          loadingUploadExcel={loadingUploadExcel}
        />
        <ImportData
          open={openImport}
          handleClickOpen={handleOpenImport}
          handleClose={handleCloseImport}
          dataLoaded={dataLoaded}
          totalLoad={totalLoad}
          progress={progress}
          fileName={fileNames[0]?.name}
          handleCancelImport={handleCancelImport}
          resData={resData}
        />
        <UploadOfferLetter
          open={openOfferLetterUpdate}
          handleClose={handleCloseOfferLetterUpdate}
          data={offerLetterdataForUpdate}
          callApi={getApiCall}
        />
        <UploadCreditNote
          open={openCreditNoteUpload}
          handleClose={handleCloseCreditNoteUpload}
          data={creditNoteData}
          callApi={getApiCall}
        />
      </section>

      {viewAssignmentModal ? (
        modalAssignmentsData.length > 0 ? (
          <Modal show={open} onHide={handleClose} scrollable={true} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Assignment/s</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Text</th>
                    <th>URL</th>
                    <th>File</th>
                    <th>Upload Date</th>
                  </tr>
                </thead>
                <tbody>
                  {modalAssignmentsData.map((m, key) => {
                    return (
                      <tr key={key}>
                        <td>{m.assignment_title}</td>
                        <td>{m.text || ""}</td>
                        <td>{m.url}</td>
                        {m.file ? (
                          <td>
                            <p
                              onClick={() => window.open(m.file)}
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                            >
                              View
                            </p>
                          </td>
                        ) : (
                          <td>
                            {" "}
                            <p> No File </p>{" "}
                          </td>
                        )}
                        <td>{m.create_time}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Modal.Body>
          </Modal>
        ) : (
          <Modal show={open} onHide={handleClose} scrollable={true}>
            <Modal.Header closeButton>
              <Modal.Title>Assignment/s</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <span
                style={{
                  display: "block",
                  marginLeft: "30%",
                  fontSize: "18px",
                  color: "red",
                  fontFamily: "cursive",
                }}
              >
                No Assignment Submitted !!!
              </span>
            </Modal.Body>
          </Modal>
        )
      ) : null}

      {/* View Feedbacks */}
      {viewFeedBackModal ? (
        // modalFeedbacksData && modalFeedbacksData.length > 0 ? (
        //   <Modal show={open} onHide={handleClose} scrollable={true}>
        //     <Modal.Header closeButton>
        //       <Modal.Title>Student Feedbacks</Modal.Title>
        //     </Modal.Header>
        //     <Modal.Body>
        //       <Table striped bordered hover size="sm">
        //         <thead>
        //           <tr>
        //             <th>Recruitment_Phase</th>
        //             <th>Feedback_Given</th>
        //           </tr>
        //         </thead>
        //         <tbody>
        //           {modalFeedbacksData.map((m, key) => {
        //             return (
        //               <tr key={key}>
        //                 <td>{m.status_name || ""}</td>
        //                 <td>{m.feedback_name || "No Feedback Given :("}</td>
        //               </tr>
        //             );
        //           })}
        //         </tbody>
        //       </Table>
        //     </Modal.Body>
        //   </Modal>
        // ) : (
        //   <Modal show={open} onHide={handleClose} scrollable={true}>
        //     <Modal.Header closeButton>
        //       <Modal.Title>Student Feedbacks</Modal.Title>
        //     </Modal.Header>
        //     <Modal.Body>
        //       <span id="no-feedback"> No Feedback Added !!! </span>
        //     </Modal.Body>
        //   </Modal>
        // )
        <Modal
          show={open}
          onHide={handleClose}
          scrollable={true}
          style={{
            top: "20%",
            left: "50%",
            transform: "translate(-50%, -20%)",
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Company Add/View Feedback</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <LoadingOverlay
              active={adminFeedbackLoader}
              spinner
              text="Please Wait..."
            >
              {modalFeedbacksData && modalFeedbacksData.length > 0 ? (
                <Table striped bordered hover size="sm">
                  <thead style={{ whiteSpace: "nowrap" }}>
                    <tr>
                      <th>Round</th>
                      <th>Date</th>
                      <th>Given by</th>
                      <th>Feedback</th>
                    </tr>
                  </thead>
                  <tbody>
                    {modalFeedbacksData.map((m, key) => {
                      return (
                        <tr key={key}>
                          <td>{m?.status_name}</td>
                          <td>{formatDate(m?.create_time)}</td>
                          <td>{m?.user_name}</td>
                          <td>{m?.comment || m?.feedback_name}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : null}

              <div style={{ marginTop: "20px" }}>
                <Typography component="legend">Give Feedback:</Typography>
                <TextareaAutosize
                  aria-label="empty textarea"
                  placeholder="Feedback"
                  style={{ height: "100px", width: "100%" }}
                  onChange={(event) => {
                    setAdminFeedback(event.target.value);
                  }}
                />
              </div>
            </LoadingOverlay>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                submitAdminFeedback();
              }}
            >
              Add Feedback
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}

      {/* View Actions */}
      {viewActionByModal ? (
        modalActionByData && modalActionByData.length > 0 ? (
          <Modal show={open} onHide={handleClose} scrollable={true}>
            <Modal.Header closeButton>
              <Modal.Title>Action for this Applicant</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table striped bordered hover size="sm">
                <thead style={{ whiteSpace: "nowrap" }}>
                  <tr>
                    <th>Status From</th>
                    <th>Status To</th>
                    <th>Reviewer Name</th>
                    <th>Reviewer User Type</th>
                  </tr>
                </thead>
                <tbody>
                  {modalActionByData.map((m, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          {m.status_from || statusCodes[m.status_from_id] || ""}
                        </td>
                        <td>
                          {m.status_to || statusCodes[m.status_to_id] || ""}
                        </td>
                        <td>{`${m.reviewer_first_name} ${m.reviewer_last_name}`}</td>
                        <td>{m.reviewer_user_type_name}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Modal.Body>
          </Modal>
        ) : (
          <Modal show={open} onHide={handleClose} scrollable={true}>
            <Modal.Header closeButton>
              <Modal.Title>Action for this Applicant</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <span id="no-feedback"> No Action Taken Yet !!! </span>
            </Modal.Body>
          </Modal>
        )
      ) : null}

      <Dialog
        open={openPaymentDialog}
        onClose={() => setOpenPaymentDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        height="60vh"
      >
        <DialogTitle id="form-dialog-title">Payment Details</DialogTitle>

        <DialogContent>
          {selectedRow?.invoicing_details?.payment_details?.payment_type ===
          "Partial Amount" ? (
            <>
              {selectedRow?.invoicing_details?.payment_details?.payments
                .length === 1 ? (
                <>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    inputProps={{
                      style: {
                        padding: "16px",
                      },
                    }}
                    label="Total bill value"
                    type="text"
                    disabled
                    value={selectedRow?.invoicing_details?.total_bill_value}
                    // onChange={(e) => {
                    //   setMinStipend(e.target.value);
                    // }}
                  />

                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    inputProps={{
                      style: {
                        padding: "16px",
                      },
                    }}
                    label="Received Amount"
                    type="text"
                    value={
                      selectedRow?.invoicing_details?.payment_details
                        ?.payments[0]?.received_amount
                    }
                    disabled
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    inputProps={{
                      style: {
                        padding: "16px",
                      },
                    }}
                    label="Additional Pending amount"
                    type="number"
                    value={pendingAmount}
                    onChange={(e) => setPendingAmount(e.target.value)}
                  />

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "16px",
                      }}
                    >
                      <KeyboardDatePicker
                        // disableToolbar
                        variant="outlined"
                        size="small"
                        openTo="year"
                        views={["year", "month", "date"]}
                        format="dd/MM/yyyy"
                        inputVariant="outlined"
                        id="date-picker-inline"
                        // defaultValue="MM/YYYY"
                        label="Date of Receive"
                        value={paymentReceiveDate}
                        onChange={(date) => setPaymentReceiveDate(date)}
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                        }}
                        style={{ width: "80%" }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        InputProps={{
                          className: classes.input,
                        }}
                      />
                      {paymentReceiveDate && (
                        <IconButton
                          aria-label="delete"
                          onClick={() => setPaymentReceiveDate(null)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </MuiPickersUtilsProvider>
                </>
              ) : (
                <>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    inputProps={{
                      style: {
                        padding: "16px",
                      },
                    }}
                    label="Total bill value"
                    type="text"
                    disabled
                    value={selectedRow?.invoicing_details?.total_bill_value}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    inputProps={{
                      style: {
                        padding: "16px",
                      },
                    }}
                    label="1st Payment Amount"
                    type="text"
                    disabled
                    value={
                      selectedRow?.invoicing_details?.payment_details
                        ?.payments[0]?.received_amount
                    }
                  />
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "16px",
                      }}
                    >
                      <KeyboardDatePicker
                        // disableToolbar
                        value={
                          selectedRow?.invoicing_details?.payment_details
                            ?.payments[0]?.date_of_receive
                        }
                        disabled
                        variant="outlined"
                        size="small"
                        openTo="year"
                        views={["year", "month", "date"]}
                        format="dd/MM/yyyy"
                        inputVariant="outlined"
                        id="date-picker-inline"
                        // defaultValue="MM/YYYY"
                        label="1st payment date"
                        // value={paymentReceiveDate}
                        // onChange={(date) => setPaymentReceiveDate(date)}
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                        }}
                        style={{ width: "80%" }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        InputProps={{
                          className: classes.input,
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>

                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    inputProps={{
                      style: {
                        padding: "16px",
                      },
                    }}
                    label="2st Payment Amount"
                    type="text"
                    disabled
                    value={
                      selectedRow?.invoicing_details?.payment_details
                        ?.payments[1]?.received_amount
                    }
                  />
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "16px",
                      }}
                    >
                      <KeyboardDatePicker
                        // disableToolbar
                        value={
                          selectedRow?.invoicing_details?.payment_details
                            ?.payments[1]?.date_of_receive
                        }
                        disabled
                        variant="outlined"
                        size="small"
                        openTo="year"
                        views={["year", "month", "date"]}
                        format="dd/MM/yyyy"
                        inputVariant="outlined"
                        id="date-picker-inline"
                        // defaultValue="MM/YYYY"
                        label="2st payment date"
                        // value={paymentReceiveDate}
                        // onChange={(date) => setPaymentReceiveDate(date)}
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                        }}
                        style={{ width: "80%" }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        InputProps={{
                          className: classes.input,
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </>
              )}
            </>
          ) : (
            <>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                inputProps={{
                  style: {
                    padding: "16px",
                  },
                }}
                label="Total bill value"
                type="text"
                disabled
                value={selectedRow?.invoicing_details?.total_bill_value}
                // onChange={(e) => {
                //   setMinStipend(e.target.value);
                // }}
              />

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                inputProps={{
                  style: {
                    padding: "16px",
                  },
                }}
                label="Received Amount"
                type="text"
                value={
                  selectedRow?.invoicing_details?.payment_details?.payments[0]
                    ?.net_amount_received
                }
                disabled
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                inputProps={{
                  style: {
                    padding: "16px",
                  },
                }}
                label="TDS"
                type="text"
                value={
                  selectedRow?.invoicing_details?.payment_details?.payments[0]
                    ?.tds
                }
                disabled
                // onChange={(e) => setPendingAmount(e.target.value)}
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "16px",
                  }}
                >
                  <KeyboardDatePicker
                    // disableToolbar
                    value={
                      selectedRow?.invoicing_details?.payment_details
                        ?.payments[0]?.date_of_receive
                    }
                    disabled
                    variant="outlined"
                    size="small"
                    openTo="year"
                    views={["year", "month", "date"]}
                    format="dd/MM/yyyy"
                    inputVariant="outlined"
                    id="date-picker-inline"
                    // defaultValue="MM/YYYY"
                    label="Date of Receive"
                    // value={paymentReceiveDate}
                    // onChange={(date) => setPaymentReceiveDate(date)}
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    style={{ width: "80%" }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                  {/* {paymentReceiveDate && (
                    <IconButton
                      aria-label="delete"
                      onClick={() => setPaymentReceiveDate(null)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )} */}
                </Grid>
              </MuiPickersUtilsProvider>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button color="primary" onClick={() => setOpenPaymentDialog(false)}>
            Close
          </Button>
          {selectedRow?.invoicing_details?.payment_details?.payment_type ===
            "Partial Amount" &&
          selectedRow?.invoicing_details?.payment_details?.payments.length ===
            1 ? (
            <Button color="primary" onClick={pendingPaymentSend}>
              Save
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>

      {/* View Admin Feedbacks */}
      {viewAdminFeedbackModal ? (
        <Modal
          show={open}
          onHide={handleClose}
          scrollable={true}
          style={{
            top: "20%",
            left: "50%",
            transform: "translate(-50%, -20%)",
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>GW Admin Feedback</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <LoadingOverlay
              active={adminFeedbackLoader}
              spinner
              text="Please Wait..."
            >
              {modalAdminFeedbackData && modalAdminFeedbackData.length > 0 ? (
                <Table striped bordered hover size="sm">
                  <thead style={{ whiteSpace: "nowrap" }}>
                    <tr>
                      <th>Round</th>
                      <th>Date</th>
                      <th>Given by</th>
                      <th>Feedback</th>
                    </tr>
                  </thead>
                  <tbody>
                    {modalAdminFeedbackData.map((m, key) => {
                      return (
                        <tr key={key}>
                          <td>{m?.status_name}</td>
                          <td>{m?.timestamp}</td>
                          <td>{m?.user_name}</td>
                          <td>{m?.feedback}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : null}

              <div style={{ marginTop: "20px" }}>
                <Typography component="legend">Give Feedback:</Typography>
                <TextareaAutosize
                  aria-label="empty textarea"
                  placeholder="Feedback"
                  style={{ height: "100px", width: "100%" }}
                  onChange={(event) => {
                    setAdminFeedback(event.target.value);
                  }}
                />
              </div>
            </LoadingOverlay>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                submitAdminFeedback();
              }}
            >
              Add Feedback
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
      {showFilter && (
        <JobTrackFilter
          open={showFilter}
          handleClose={() => {
            setShowFilter(false);
          }}
          filters={filters}
          setFilters={setFilters}
          jobId={job_id}
        />
      )}
      <ShareApplicantTrack
        jobProfileName={jobTitle}
        jobProfileObject={{
          jobTitle: jobTitle,
          jobId: job_id,
          compId: comp_id,
        }}
        fromWithoutAction={true}
        ratingColumns={shareColumns}
        stepList={stepList}
        open={shareOpen}
        setOpen={setShareOpen}
      />

      <Typography component={"div"}>{getStepContent(activeStep)}</Typography>
    </div>
  ) : (
    <img alt="Getwork Loading Gif..." src={gif} style={gifStyling} />
  );
}

export default JobTrack;
